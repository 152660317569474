/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Venta de servicios profesionales
    </h1>

    <div class="global-form-hint-container" v-if="!puchaseView">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado. </span> Recuerda llenar los
      campos obligatorios antes de poder agregar artículos a la venta.
    </div>

    <div v-if="!puchaseView">
      <div
        v-if="selectedClientInformation && newSale.CONDICION_P === 'R'"
        class="special-margin"
      >
        <h3 class="form-division">Crédito disponible</h3>
        <b-field
          label-position="inside"
          label="Crédito disponible"
          class="special-margin"
        >
          <MoneyInput
            currency="MXN"
            :digits="5"
            placeholder="Ejemplo: 16"
            :required="false"
            :value="selectedClientInformation.remainingCredit"
            :disabled="true"
          >
          </MoneyInput>
        </b-field>
        <b-progress
          :rounded="false"
          type="is-success"
          size="is-medium"
          :value="selectedClientInformation.remainingCredit"
          :max="selectedClientInformation.LIMITE_CRED"
          show-value
          format="raw"
          :precision="2"
          :keep-trailing-zeroes="true"
          locale="es-MX"
        >
          {{ selectedClientInformation.remainingCredit | money("MXN", 2) }}
        </b-progress>
      </div>

      <h3 class="form-division">Información general</h3>

      <b-checkbox
        v-model="newSale.requireInvoice"
        class="permissions-checkbox first-row-checkbox"
        @input="handleRequireInvoice()"
        v-if="
          newSale.CLIENTE &&
          checkIfCanInvoice(newSale.CLIENTE) &&
          userPermissions &&
          userPermissions.accounting.saveInvoiceProfessionalServicesSale === 'S'
        "
        :disabled="
          !newSale.CLIENTE ||
          saleInformation ||
          (saleInformation && saleInformation.STATUS_FAC === 'TI') ||
          (saleInformation && saleInformation.STATUS_FAC === 'FD')
        "
      >
        Facturar venta
      </b-checkbox>

      <div class="invoice-add-item special-margin">
        <b-field
          class="invoice-add-secondary-item-one"
          label-position="inside"
          label="Razón social extra (Opc)"
        >
          <b-select
            placeholder="Ejemplo: Sin razón social extra"
            icon="domain"
            expanded
            v-model="newSale.RAZON_SOCIAL"
            :disabled="
              saleInformation ||
              (saleInformation && saleInformation.STATUS_FAC === 'TI') ||
              (saleInformation && saleInformation.STATUS_FAC === 'FD')
            "
          >
            <option :value="undefined">Sin razón social extra</option>
            <option
              :value="singleCompanyName._id"
              v-for="singleCompanyName in companyNames"
              :key="singleCompanyName._id"
            >
              {{ singleCompanyName.NOMBRE_FAC }}
            </option>
          </b-select>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-one"
          label-position="inside"
          label="Vendedor (Opc)"
        >
          <b-select
            placeholder="Ejemplo: Sin vendedor"
            icon="account-tie"
            expanded
            v-model="newSale.VENDEDOR"
            :disabled="
              saleInformation ||
              (saleInformation && saleInformation.STATUS_FAC === 'TI') ||
              (saleInformation && saleInformation.STATUS_FAC === 'FD')
            "
          >
            <option :value="undefined">Sin vendedor</option>
            <option
              :value="singleEmployee._id"
              v-for="singleEmployee in employees"
              :key="singleEmployee._id"
            >
              {{ singleEmployee.NOMBRE }} {{ " " }} {{ singleEmployee.PATERNO }}
              {{ " " }}
              {{ singleEmployee.MATERNO }}
            </option>
          </b-select>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-second required-input"
          label-position="inside"
          label="Cliente (Req)"
        >
          <b-autocomplete
            :data="autoCompleteClients(clientName)"
            field="NOMBRE_EMPRESA"
            v-model="clientName"
            :disabled="
              saleInformation ||
              (saleInformation && saleInformation.STATUS_FAC === 'TI') ||
              (saleInformation && saleInformation.STATUS_FAC === 'FD')
            "
            placeholder="Cliente"
            icon="earth"
            open-on-focus
            expanded
            clearable
            @select="
              (option) => {
                if (option) {
                  newSale.CLIENTE = option._id;
                  handleClientChange(option);
                }
              }
            "
            check-infinite-scroll
          >
            <template #empty>No se encontraron clientes</template>
          </b-autocomplete>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-second"
          label-position="inside"
          label="RFC del cliente (Req)"
        >
          <b-input
            disabled
            placeholder="Ejemplo: XAXX010101000"
            :value="getClientRFC()"
          >
          </b-input>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-four"
          label-position="inside"
          label="Uso del CFDI (Req)"
          expanded
        >
          <b-select
            :disabled="
              saleInformation &&
              saleInformation.STATUS_FAC &&
              saleInformation.STATUS_FAC !== 'SF'
            "
            placeholder="Ejemplo: Uso del CFDI"
            icon="domain"
            expanded
            v-model="newSale.CLAVE_USO_SAT"
            required
            class="required-input"
          >
            <option value="" disabled>Seleccione un uso del CFDI</option>
            <option
              v-for="singleCfdiUse in cfdiUses"
              :value="singleCfdiUse._id"
              :key="singleCfdiUse._id"
            >
              {{ singleCfdiUse.CVE_USO_SAT }} {{ singleCfdiUse.DESCRIPCION }}
            </option>
          </b-select>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-four"
          label="Régimen fiscal (Req)"
          expanded
          label-position="inside"
        >
          <b-select
            :disabled="
              saleInformation &&
              saleInformation.STATUS_FAC &&
              saleInformation.STATUS_FAC !== 'SF'
            "
            placeholder="Ejemplo: Régimen fiscal"
            icon="briefcase-outline"
            expanded
            v-model="newSale.CLAVE_REGIMEN"
            required
            class="required-input"
          >
            <option value="" disabled>Seleccione un régimen</option>
            <option
              v-for="singleRegimen in regimenes"
              :value="singleRegimen._id"
              :key="singleRegimen._id"
            >
              {{ singleRegimen.CVE_REG_SAT }} {{ singleRegimen.DESCRIPCION }}
            </option>
          </b-select>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-four"
          label-position="inside"
          label="Fecha (Req)"
        >
          <b-datepicker
            :disabled="blockEdit"
            required
            :show-week-number="true"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            placeholder="Fecha de creación"
            icon="calendar-today"
            v-model="newSale.FECHA"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-four required-input"
          label-position="inside"
          label="Almacen (Req)"
        >
          <b-select
            :disabled="blockEdit"
            placeholder="Almacen"
            icon="warehouse"
            v-model="newSale.ALMACEN"
            expanded
            rquired
          >
            <option value="" disabled>Seleccione un almacén</option>
            <option
              v-for="warehouse in warehouses"
              :value="warehouse._id"
              :key="warehouse.CLAVE_ALMACEN"
            >
              {{ warehouse.NOMBRE_ALM }}
            </option>
          </b-select>
        </b-field>
      </div>

      <h3 class="form-division">Información de pago</h3>
      <div class="invoice-add-item special-margin">
        <b-field
          class="required-input"
          :class="
            newSale.CONDICION_P !== 'R'
              ? 'invoice-add-secondary-item-four'
              : 'invoice-add-secondary-item-three'
          "
          label-position="inside"
          label="Condición de pago (Req)"
        >
          <b-select
            :disabled="blockEdit"
            placeholder="Condición de pago"
            icon="credit-card-settings-outline"
            v-model="newSale.CONDICION_P"
            expanded
            @input="handlePaymentConditionChange()"
          >
            <option value="" disabled>Seleccione una condición de pago</option>
            <option value="C">Contado</option>
            <option value="R">Crédito</option>
          </b-select>
        </b-field>
        <b-field
          class="required-input"
          :class="
            newSale.CONDICION_P !== 'R'
              ? 'invoice-add-secondary-item-four'
              : 'invoice-add-secondary-item-three'
          "
          label-position="inside"
          label="Método de pago (Req)"
        >
          <b-select
            :disabled="blockEdit"
            placeholder="Método de pago"
            icon="numeric"
            v-model="newSale.METODO_P"
            expanded
            @input="handlePaymentMethodChange()"
          >
            <option value="" disabled>Seleccione un método de pago</option>
            <option value="P">Pago en una sola exhibición (PUE)</option>
            <option value="D">Pago en parcialidades o diferido (PPD)</option>
          </b-select>
        </b-field>
        <b-field
          v-if="newSale.CONDICION_P !== 'R'"
          class="invoice-add-secondary-item-four"
          label="Forma de pago (Req)"
          expanded
          label-position="inside"
        >
          <b-select
            :disabled="blockEdit"
            placeholder="Ejemplo: Forma de pago"
            icon="credit-card-multiple-outline"
            expanded
            v-model="newSale.FORMA_P"
            required
            class="required-input"
          >
            <option value="" disabled>Selecciones una forma de pago</option>
            <option
              :value="singlePaymentType._id"
              v-for="singlePaymentType in paymentTypes"
              v-bind:key="singlePaymentType._id"
            >
              {{ singlePaymentType.DESCRIBE_P }}
            </option>
          </b-select>
        </b-field>
        <b-field
          label-position="inside"
          label="Días de crédito (Req)"
          :class="
            newSale.CONDICION_P !== 'R'
              ? 'invoice-add-secondary-item-four'
              : 'invoice-add-secondary-item-three'
          "
        >
          <b-input
            required
            placeholder="Días de crédito"
            type="number"
            step="any"
            icon="calendar-range"
            v-model="newSale.PERI_PAGO"
            :disabled="newSale.CONDICION_P === 'C' || blockEdit"
          >
          </b-input>
        </b-field>

        <b-field
          v-if="newSale.CONDICION_P !== 'R'"
          class="invoice-add-secondary-item-four"
          label="Número de cuenta (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            :disabled="blockEdit"
            placeholder="Ejemplo: Martillo azul con mango de madera"
            expanded
            icon="card-account-details-outline"
            v-model="newSale.NO_CUENTA"
          ></b-input>
        </b-field>

        <b-field
          v-if="newSale.CONDICION_P !== 'R'"
          class="invoice-add-secondary-item-four"
          label="CLABE interbancaria (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            :disabled="blockEdit"
            placeholder="Ejemplo: 000000000000000000"
            expanded
            icon="bank-outline"
            v-model="newSale.NO_CLABE"
          ></b-input>
        </b-field>
        <b-field
          v-if="newSale.CONDICION_P !== 'R'"
          label="Número de tarjeta (Opcional)"
          class="invoice-add-secondary-item-four"
          expanded
          label-position="inside"
        >
          <b-input
            :disabled="blockEdit"
            placeholder="Ejemplo: 0000000000000000"
            expanded
            icon="card-text-outline"
            v-model="newSale.NO_TARJETA"
          ></b-input>
        </b-field>
        <b-field
          v-if="newSale.CONDICION_P !== 'R'"
          label="Número de cheque (Opcional)"
          class="invoice-add-secondary-item-four"
          expanded
          label-position="inside"
        >
          <b-input
            :disabled="blockEdit"
            placeholder="Ejemplo: 12398"
            expanded
            icon="card-bulleted-outline"
            v-model="newSale.NO_CHEQUE"
          ></b-input>
        </b-field>
      </div>
    </div>

    <section v-if="!checkDisable" class="special-margin">
      <div class="invoice-box">
        <div class="invoice-upper-part">
          <div class="invoice-logo"></div>
          <div class="invoice-information">
            Fecha de creación: {{ creationDateFomatted }}
          </div>
        </div>

        <div class="invoice-company-info-part">
          <div class="invoice-company" v-if="newSale.CLIENTE">
            <p v-if="newSale.CLIENTE && newSale.CLIENTE.NOMBRE_EMPRESA">
              {{ newSale.CLIENTE.NOMBRE_EMPRESA }}<br />
            </p>
            <p v-if="newSale.CLIENTE && newSale.CLIENTE.DOMICIL_CLI">
              {{ newSale.CLIENTE.DOMICIL_CLI }}
            </p>
            <p v-if="newSale.CLIENTE && newSale.CLIENTE.CLAVE_ESTADO">
              {{ newSale.CLIENTE.CLAVE_ESTADO }},{{ newSale.CLIENTE.CP_PRV }}
              <br />
            </p>
          </div>
          <div class="invoice-company-person"></div>
        </div>

        <div class="invoice-payment-info-part">
          <div class="invoice-payment-type-left">
            <strong>Observaciones impresas</strong>
          </div>
          <div class="invoice-payment-type-right">
            <strong>Observaciones internas</strong>
          </div>
        </div>

        <div class="invoice-payment-type-info">
          <div class="invoice-payment-type-info-left invoice-observation">
            <b-input type="textarea" v-model="newSale.OBSERVA_IMP"></b-input>
          </div>
          <div class="invoice-payment-type-info-right invoice-observation">
            <b-input type="textarea" v-model="newSale.OBSERVA"></b-input>
          </div>
        </div>

        <h3 class="purchase-order-table-title">Servicios en venta</h3>

        <div class="global-lists-search no-margin special-margin">
          <div class="global-lists-filters-container"></div>
          <div class="global-lists-filters-columns no-margin">
            <b-checkbox
              v-for="singleColumn in columns"
              v-bind:key="singleColumn.label"
              v-model="singleColumn.display"
            >
              {{ singleColumn.label }}
            </b-checkbox>
          </div>
        </div>

        <b-table
          pagination-position="both"
          :data="newSale.currentItems"
          :striped="true"
          :bordered="true"
          :hoverable="true"
          ref="purchaseOrder"
          custom-row-key="RENGLON"
          paginated
          :current-page="currentPage"
          :per-page="perPage"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort-direction="asc"
          class="purchase-order-table"
          scrollable
          detailed
          detail-key="RENGLON"
        >
          <template #empty>
            <div class="has-text-centered">No hay servicios</div>
          </template>

          <template slot-scope="props">
            <b-table-column
              label="Acciones"
              v-if="!blockEdit"
              :visible="columns[0].display"
            >
              <b-field class="invoice-option-select">
                <b-select
                  placeholder="Opciones"
                  v-model="props.row.selectedOption"
                  @input="handleOptionChange(props.row)"
                  v-if="props.row.service"
                >
                  <option value="">Selecciona una opción</option>
                  <option value="deleteItem">Borrar servicio</option>
                </b-select>
              </b-field>
            </b-table-column>

            <!-- eslint-disable vue/no-use-v-if-with-v-for -->
            <b-table-column
              label="Servicio"
              v-if="!props.row.service"
              :visible="columns[1].display"
            >
              <div class="invoice-article-div">
                <b-field class="invoice-article-select">
                  <b-select
                    placeholder="Servicio"
                    icon="hammer"
                    v-model="props.row.service"
                    @input="
                      (value) => {
                        props.row.CLAVE_SERVICIO = value._id;
                        props.row.PRECIO_U = value.COSTO_MIN_VTA;
                        props.row.CONCEP_IE = value.CLAVE_SERVICIO;
                        props.row.DESCRIBEAR = value.DESCRIPCION;

                        if (!value.RETIENE_ISR) {
                          props.row.PORC_RETENCION_ISR = 0;
                        } else {
                          props.row.PORC_RETENCION_ISR =
                            value.CANT_RETENCION_ISR;
                        }

                        if (!value.RETIENE_IVA) {
                          props.row.PORC_RETENCION_IVA = 0;
                        } else {
                          props.row.PORC_RETENCION_IVA =
                            value.CANT_RETENCION_IVA;
                        }

                        props.row.PORC_IVA = value.CANT_IVA;
                        updateTotals();
                        addRow();
                      }
                    "
                    :disabled="blockEdit"
                  >
                    <option value="" disabled>Servicio</option>
                    <option
                      v-for="service in services"
                      v-if="checkServiceRepeated(service)"
                      :value="service"
                      :key="service.CLAVE_SERVICIO"
                    >
                      {{ service.DESCRIPCION }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </b-table-column>
            <!-- eslint-enable -->

            <b-table-column
              label="Servicio"
              field="DESCRIBEAR"
              sortable
              v-if="props.row.service"
              :visible="columns[1].display"
            >
              <b-input
                v-if="props.row.service && !blockEdit"
                placeholder="Ejemplo: 100"
                expanded
                v-model="props.row.DESCRIBEAR"
                required
                :disabled="blockEdit"
              ></b-input>

              <span v-if="blockEdit">{{ props.row.DESCRIBEAR }}</span>
            </b-table-column>

            <!-- eslint-disable vue/no-use-v-if-with-v-for -->
            <b-table-column
              label="Cuenta predial (Opcional)"
              field="CUENTA_PREDIAL"
              sortable
            >
              <b-select
                v-if="props.row.service"
                placeholder="Cuenta predial"
                icon="home"
                v-model="props.row.CUENTA_PREDIAL"
                :disabled="blockEdit"
                @input="handlePredialAccountChange()"
              >
                <option value="" disabled>Cuenta predial</option>
                <option
                  v-for="predialAccount in getClientPredialAccounts()"
                  :value="predialAccount"
                  :key="predialAccount"
                >
                  {{ predialAccount }}
                </option>
              </b-select>
            </b-table-column>
            <!-- eslint-enable -->

            <b-table-column
              label="Costo"
              field="PRECIO_U"
              sortable
              :visible="columns[2].display"
            >
              <b-input
                placeholder="Ejemplo: 100"
                type="Number"
                step="any"
                expanded
                v-model="props.row.PRECIO_U"
                required
                @input="updateTotals()"
                :disabled="blockEdit"
              ></b-input>
            </b-table-column>

            <b-table-column
              label="Cantidad"
              field="CANTIDAD"
              sortable
              :visible="columns[3].display"
            >
              <b-input
                placeholder="Ejemplo: 1"
                type="Number"
                step="any"
                expanded
                v-model="props.row.CANTIDAD"
                required
                @input="updateTotals()"
                :disabled="blockEdit"
              ></b-input>
            </b-table-column>

            <b-table-column
              label="Porcentaje de IVA"
              field="PORC_IVA"
              sortable
              :visible="columns[4].display"
            >
              <b-input
                placeholder="Ejemplo: 0"
                type="number"
                step="any"
                expanded
                v-model="props.row.PORC_IVA"
                @input="updateTotals()"
                :disabled="blockEdit"
              />
            </b-table-column>

            <b-table-column
              label="Porcentaje de retención de ISR"
              field="PORC_RETENCION_ISR"
              sortable
              :visible="columns[5].display"
            >
              <b-input
                placeholder="Ejemplo: 0"
                type="number"
                step="any"
                expanded
                v-model="props.row.PORC_RETENCION_ISR"
                @input="updateTotals()"
                :disabled="blockEdit"
              />
            </b-table-column>

            <b-table-column
              label="Porcentaje de retención de IVA"
              field="PORC_RETENCION_IVA"
              sortable
              :visible="columns[6].display"
            >
              <b-input
                placeholder="Ejemplo: 0"
                type="number"
                step="any"
                expanded
                v-model="props.row.PORC_RETENCION_IVA"
                @input="updateTotals()"
                :disabled="blockEdit"
              />
            </b-table-column>

            <b-table-column
              label="Total del servicio"
              field="TOTAL"
              sortable
              :visible="columns[7].display"
            >
              <b-input
                placeholder="Ejemplo: 0"
                type="number"
                step="any"
                expanded
                v-model="props.row.TOTAL"
                required
                disabled
              ></b-input>
            </b-table-column>
          </template>

          <template slot="detail">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <div class="title-expandable-item">
                    <p>Sin información que mostrar</p>
                  </div>
                </div>
              </div>
            </article>
          </template>
        </b-table>

        <div v-if="!puchaseView">
          <div class="invoice-payment-total-info-costs">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>Subtotal:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newSale.subtotal | money("MXN", 2)
              }}</strong>
            </div>
          </div>

          <div class="invoice-payment-total-info-discounts">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>Indirectos:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newSale.costs | money("MXN", 2)
              }}</strong>
            </div>
          </div>

          <div class="invoice-payment-total-info-discounts">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>Descuentos:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newSale.discounts | money("MXN", 2)
              }}</strong>
            </div>
          </div>

          <div class="invoice-payment-total-info-discounts">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>IVA:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newSale.iva | money("MXN", 2)
              }}</strong>
            </div>
          </div>

          <div class="invoice-payment-total-info-discounts">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>Retención ISR:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newSale.RETENCION_ISR_GTO | money("MXN", 2)
              }}</strong>
            </div>
          </div>

          <div class="invoice-payment-total-info-discounts">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>Retención IVA:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newSale.RETENCION_IVA_GTO | money("MXN", 2)
              }}</strong>
            </div>
          </div>

          <div class="invoice-payment-total-info">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>Total:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newSale.total | money("MXN", 2)
              }}</strong>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div
      class="add-global-controls"
      v-if="saleInformation && blockEdit && !puchaseView"
    >
      <b-button
        v-if="
          (!saleInformation.STATUS_FAC ||
            saleInformation.STATUS_FAC === 'SF') &&
          checkIfCanInvoice(newSale.CLIENTE) &&
          saleInformation.STATUS !== 'CA'
        "
        type="is-success"
        @click="
          startConfirmationEvent(
            'success',
            function () {
              saveInvoice();
            },
            'facturar venta'
          )
        "
        >Facturar venta</b-button
      >

      <b-tooltip
        v-if="
          (!saleInformation.STATUS_FAC ||
            saleInformation.STATUS_FAC === 'SF') &&
          !checkIfCanInvoice(newSale.CLIENTE)
        "
        position="is-right"
        label="Primero debes llenar todos los datos de facturación requeridos de tu compañía en la sección de 'configuración' y asegurarte que el cliente tenga RFC y código postal"
        multilined
      >
        <b-button type="is-success" disabled>Facturar venta</b-button>
      </b-tooltip>

      <b-button
        v-if="saleInformation"
        type="is-success"
        @click="saveNewSale(1, true)"
        >Editar venta</b-button
      >

      <b-button
        type="is-danger"
        @click="closeModal()"
        v-bind:class="{
          'cancel-button': saleInformation,
        }"
        >Regresar</b-button
      >
    </div>

    <div class="add-global-controls" v-if="!saleInformation && !puchaseView">
      <b-button
        type="is-success"
        @click="saveNewSale(0)"
        :disabled="checkDisableItems"
        >Crear venta</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>

    <div class="add-global-controls" v-if="puchaseView">
      <b-button type="is-success" @click="selectSale()"
        >Seleccionar venta</b-button
      >

      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import SearchClientUnlock from "./SearchClientUnlock";
import { EventBus } from "../../../event-bus";
import {
  round,
  toFixedNoRound,
  capitalizeFirstLetter,
  createRandomStringCamps,
} from "../../../utils/fns";
import {
  openConfirmationModalSucces,
  fillObjectForm,
} from "../../../utils/component-fns";
import CreditNoteRelations from "../../../data/credit-note-relations";
import { paymentTypeEfectivo } from "../../../utils/env-variables-fns";
import { paymentTypePorDefinir } from "../../../utils/env-variables-fns";
import { regimenPersonaMoral } from "../../../utils/env-variables-fns";

export default {
  name: "AddProfessionalServiceSale",
  components: {},
  props: [
    "currentIndexProp",
    "saleInformation",
    "blockEdit",
    "puchaseView",
    "purchaseSale",
  ],
  data() {
    return {
      clientName: "",
      efectivoPaymentId: paymentTypeEfectivo,
      porDefinirPaymentId: paymentTypePorDefinir,
      currentClientInformation: null,
      currentPage: 1,
      perPage: 10,
      currentIndex: this.currentIndexProp ? this.currentIndexProp : 1,
      datepicker: {
        "month-names": [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        "day-names": ["D", "L", "M", "M", "J", "V", "S"],
        "show-week-number": true,
        locale: "es-MX",
      },
      newSale: {
        CLIENTE: "",
        ALMACEN: "",
        OBSERVA: "",
        OBSERVA_IMP: "",
        currentItems: [
          {
            RENGLON: this.currentIndex,
            CONCEP_IE: "",
            CLAVE_SERVICIO: "",
            CUENTA_PREDIAL: "",
            service: null,
            PORC_IVA: 16,
            PORC_RETENCION_ISR: 0,
            PORC_RETENCION_IVA: 0,
            RETENCION_ISR_GTO: 0,
            RETENCION_IVA_GTO: 0,
            PORC_IEPS: 0,
            PRECIO_U: 0,
            IVA_GTO: 0,
            IEPS_GTO: 0,
            COST_GTO: 0,
            DESC_GTO: 0,
            ES_ACTIVOF: "N",
            DESCRIBEAR: "",
            SUB_TOT: 0,
            TOTAL: 0,
            CANTIDAD: 1,
            EXENTO: "N",
            ES_EXENTO: "N",
          },
        ],
        FECHA: new Date(),
        subtotal: 0,
        total: 0,
        iva: 0,
        ieps: 0,
        status: "Sin verificar",
        perPage: 10,
        currentPage: 1,
        TIPO: "V",
        CONDICION_P: "C",
        METODO_P: "P",
        PERI_PAGO: 0,
        CLAVE_USO_SAT: "",
        CLAVE_REGIMEN: "",
        FORMA_P: paymentTypeEfectivo,
        NO_CUENTA: "",
        NO_CLABE: "",
        NO_TARJETA: "",
        NO_CHEQUE: "",
        requireInvoice: false,
        VENDEDOR: undefined,
        RAZON_SOCIAL: undefined,
        RETENCION_ISR_GTO: 0,
        RETENCION_IVA_GTO: 0,
      },
      unlockClientInformation: {},
      downloading: false,
      downloadingPrices: false,
      moment: moment,
      columns: [
        {
          label: "Acciones",
          display: true,
        },
        {
          label: "Artículo",
          display: true,
        },
        {
          label: "Costo",
          display: true,
        },
        {
          label: "Cantidad",
          display: true,
        },
        {
          label: "Precio",
          display: true,
        },
        {
          label: "Porcentaje de IVA",
          display: true,
        },
        {
          label: "Porcentaje de retención de ISR",
          display: false,
        },
        {
          label: "Porcentaje de retención de IVA",
          display: true,
        },
        {
          label: "Total del servicio",
          display: false,
        },
      ],
      cfdiRelations: CreditNoteRelations,
      regimenPersonaMoral: regimenPersonaMoral,
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    // Fill the service information in form fields
    if (this.saleInformation) {
      // Fill the form with received information
      fillObjectForm(this.saleInformation, this.newSale);

      if (this.saleInformation.FECHA) {
        this.newSale.FECHA = new Date(this.saleInformation.FECHA);
      }

      if (this.saleInformation._id) {
        this.newSale._id = this.saleInformation._id;
      }

      // If sale payment is credit then set payment values to undefine and false
      if (this.saleInformation.CONDICION_P === "R") {
        this.newSale.METODO_P = "D";
      }

      // Fill in client information
      for (const singleClient of this.clients) {
        if (singleClient._id === this.saleInformation.CLIENTE) {
          this.currentClientInformation = singleClient;
          this.clientName = singleClient.NOMBRE_EMPRESA;
          break;
        }
      }

      this.newSale.RAZON_SOCIAL = this.saleInformation.RAZON_SOCIAL;

      this.updateTotals();
    }

    if (!this.saleInformation) {
      for (const singleUser of this.users) {
        if (singleUser.email === this.user.email) {
          for (const singleEmployee of this.employees) {
            if (singleUser._id === singleEmployee.USER) {
              this.newSale.VENDEDOR = singleEmployee._id;
            }
          }
        }
      }
    }
  },
  beforeDestroy() {
    EventBus.$off();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    capitalizeString(string) {
      return capitalizeFirstLetter(string);
    },
    /**
     *
     * @desc saves or updates a sale depending on type
     */
    async saveNewSale(saveType, showModal = true, showServicesWarning = true) {
      try {
        if (!this.checkClientCanPurchase) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la venta revise la línea de crédito disponible`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        if (!this.checkSale(this.newSale)) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la venta revise los campos obligatorios`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        let saveSaleData = this.transformSale(this.newSale);
        let response = await this.$store.dispatch(
          saveType === 0
            ? "SAVEPROFESSIONALSERVICESSALE"
            : "EDITPROFESSIONALSERVICESSALE",
          saveSaleData
        );
        if (response == "Success") {
          if (showModal) {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: `Se guardó exitosamente la venta`,
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          }

          return "Success";
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la venta, si es un error de facturación puede intentarlo de nuevo en el detalle de la venta: ${JSON.stringify(
              response
            )}`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al guardar la venta en la base de datos, si es un error de facturación puede intentarlo de nuevo en el detalle de la venta: ${JSON.stringify(
            error
          )}`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc creates a new invoice of a sale
     */
    async saveInvoice() {
      try {
        let response = await this.$store.dispatch(
          "SAVEPROFESSIONALSERVICESINVOICE",
          {
            saleId: this.newSale._id,
          }
        );
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: `Se generó la factura exitosamente`,
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al generar la factura:  ${
              response ? JSON.stringify(response) : "Error en el servidor"
            }`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al generar la factura en la base de datos: ${
            error ? JSON.stringify(error) : "Error en el servidor"
          }`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    checkSale(sale) {
      if (!sale.CLIENTE) return false;
      if (!sale.ALMACEN) return false;
      if (sale.PERI_PAGO === "") return false;
      if (!sale.CONDICION_P) return false;
      if (!sale.CLAVE_USO_SAT) return false;
      if (!sale.CLAVE_REGIMEN) return false;
      if (!sale.METODO_P) return false;
      if (sale.currentItems.length === 0) return false;
      return true;
    },
    transformSale(sale) {
      let transformedSale = {};
      transformedSale.TIPO = sale.TIPO;
      transformedSale.OBSERVA = sale.OBSERVA;
      transformedSale.OBSERVA_IMP = sale.OBSERVA_IMP;
      transformedSale.FECHA = sale.FECHA;
      transformedSale.CLAVE_ALMACEN = sale.ALMACEN;
      transformedSale.CLAVE_CLIENTE = sale.CLIENTE;
      transformedSale.TOTAL = sale.total;
      transformedSale.RESTA = sale.total;
      transformedSale.N_PACRIALIDAD = 1;
      transformedSale.SUBTOTAL = sale.subtotal;
      transformedSale.IVA = sale.iva;
      transformedSale.IEPS = sale.ieps;
      transformedSale.STATUS = "AC";
      transformedSale.CONDICION_P = sale.CONDICION_P;
      transformedSale.METODO_P = sale.METODO_P;
      transformedSale.PERI_PAGO = sale.PERI_PAGO;
      transformedSale.CLAVE_USO_SAT = sale.CLAVE_USO_SAT;
      transformedSale.CLAVE_REGIMEN = sale.CLAVE_REGIMEN;
      transformedSale.FORMA_P = sale.FORMA_P;
      transformedSale.NO_CUENTA = sale.NO_CUENTA;
      transformedSale.NO_CLABE = sale.NO_CLABE;
      transformedSale.NO_TARJETA = sale.NO_TARJETA;
      transformedSale.NO_CHEQUE = sale.NO_CHEQUE;
      transformedSale.requireInvoice = sale.requireInvoice;
      transformedSale.VENDEDOR = sale.VENDEDOR;
      transformedSale.RAZON_SOCIAL = sale.RAZON_SOCIAL;
      transformedSale.USUARIO_CREACION = sale.USUARIO_CREACION;
      transformedSale.USUARIO_CONFIRMACION = sale.USUARIO_CONFIRMACION;
      transformedSale.RETENCION_ISR_GTO = sale.RETENCION_ISR_GTO;
      transformedSale.RETENCION_IVA_GTO = sale.RETENCION_IVA_GTO;

      if (sale._id) transformedSale._id = sale._id;
      let items = [];
      for (let singleItem of sale.currentItems) {
        if (singleItem.service) {
          const transformedItem = {
            RENGLON: singleItem.RENGLON,
            CONCEP_IE: singleItem.CONCEP_IE,
            CLAVE_SERVICIO: singleItem.CLAVE_SERVICIO,
            CUENTA_PREDIAL: singleItem.CUENTA_PREDIAL,
            PORC_IVA: singleItem.PORC_IVA,
            PORC_RETENCION_ISR: singleItem.PORC_RETENCION_ISR,
            PORC_RETENCION_IVA: singleItem.PORC_RETENCION_IVA,
            RETENCION_ISR_GTO: singleItem.RETENCION_ISR_GTO,
            RETENCION_IVA_GTO: singleItem.RETENCION_IVA_GTO,
            PORC_IEPS: singleItem.PORC_IEPS,
            PRECIO_U: singleItem.PRECIO_U,
            COSTO_UNI: singleItem.PRECIO_U,
            IVA_GTO: singleItem.IVA_GTO,
            IEPS_GTO: singleItem.IEPS_GTO,
            COST_GTO: singleItem.COST_GTO,
            DESC_GTO: singleItem.DESC_GTO,
            ES_ACTIVOF: singleItem.ES_ACTIVOF,
            DESCRIBEAR: singleItem.DESCRIBEAR,
            SUB_TOT: singleItem.SUB_TOT,
            TOTAL: singleItem.TOTAL,
            CANTIDAD: singleItem.CANTIDAD,
            EXENTO: singleItem.EXENTO,
            ES_EXENTO: singleItem.ES_EXENTO,
          };
          if (singleItem._id) transformedItem._id = singleItem._id;
          items.push(transformedItem);
        }
      }
      transformedSale.items = items;
      return transformedSale;
    },
    addRow() {
      this.currentIndex++;
      const newRow = {
        RENGLON: this.currentIndex,
        CONCEP_IE: "",
        CLAVE_SERVICIO: "",
        CUENTA_PREDIAL: "",
        service: null,
        PORC_IVA: 16,
        PORC_RETENCION_ISR: 0,
        PORC_RETENCION_IVA: 0,
        RETENCION_ISR_GTO: 0,
        RETENCION_IVA_GTO: 0,
        PORC_IEPS: 0,
        PRECIO_U: 0,
        IVA_GTO: 0,
        IEPS_GTO: 0,
        COST_GTO: 0,
        DESC_GTO: 0,
        ES_ACTIVOF: "N",
        DESCRIBEAR: "",
        SUB_TOT: 0,
        TOTAL: 0,
        CANTIDAD: 1,
        EXENTO: "N",
        ES_EXENTO: "N",
      };
      this.newSale.currentItems.push(newRow);
    },
    /**
     *
     * @desc updates totals of the new sale and calculates taxes from all products
     */
    updateTotals(changeTotal = true) {
      this.newSale.subtotal = 0;
      this.newSale.iva = 0;
      this.newSale.ieps = 0;
      this.newSale.total = 0;
      this.newSale.RETENCION_ISR_GTO = 0;
      this.newSale.RETENCION_IVA_GTO = 0;

      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newSale.currentItems.entries()) {
        if (singleItem.service) {
          let ivaTotal =
            Number(singleItem.PRECIO_U) *
            Number(singleItem.CANTIDAD) *
            (Number(singleItem.PORC_IVA) / 100);
          let iepsTotal =
            Number(singleItem.PRECIO_U) *
            Number(singleItem.CANTIDAD) *
            (Number(singleItem.PORC_IEPS) / 100);
          let isrRetentionTotal = 0;
          let ivaRetentionTotal = 0;

          if (singleItem.service.RETIENE_ISR) {
            isrRetentionTotal +=
              Number(singleItem.PRECIO_U) *
              Number(singleItem.CANTIDAD) *
              (singleItem.PORC_RETENCION_ISR / 100);

            ivaRetentionTotal +=
              Number(singleItem.PRECIO_U) *
              Number(singleItem.CANTIDAD) *
              (singleItem.PORC_RETENCION_IVA / 100);
          }

          let total =
            Number(singleItem.PRECIO_U) * Number(singleItem.CANTIDAD) +
            Number(ivaTotal) +
            Number(iepsTotal) -
            Number(isrRetentionTotal) -
            Number(ivaRetentionTotal);

          // Set item values
          this.newSale.currentItems[singleItemIndex].SUB_TOT = toFixedNoRound(
            Number(singleItem.PRECIO_U) * Number(singleItem.CANTIDAD),
            3
          );
          this.newSale.currentItems[singleItemIndex].IVA_GTO = toFixedNoRound(
            ivaTotal,
            3
          );
          this.newSale.currentItems[singleItemIndex].IEPS_GTO = toFixedNoRound(
            iepsTotal,
            3
          );
          this.newSale.currentItems[singleItemIndex].RETENCION_ISR_GTO =
            toFixedNoRound(isrRetentionTotal, 3);
          this.newSale.currentItems[singleItemIndex].RETENCION_IVA_GTO =
            toFixedNoRound(ivaRetentionTotal, 3);
          if (changeTotal) {
            this.newSale.currentItems[singleItemIndex].TOTAL = toFixedNoRound(
              total,
              3
            );
          }
          // Set sales values
          this.newSale.subtotal += toFixedNoRound(
            Number(singleItem.PRECIO_U) * Number(singleItem.CANTIDAD),
            3
          );
          this.newSale.total += toFixedNoRound(total, 3);
          this.newSale.iva += toFixedNoRound(ivaTotal, 3);
          this.newSale.ieps = toFixedNoRound(iepsTotal, 3);
          this.newSale.RETENCION_ISR_GTO = toFixedNoRound(isrRetentionTotal, 3);
          this.newSale.RETENCION_IVA_GTO = toFixedNoRound(ivaRetentionTotal, 3);
        }
      }
    },
    handleClientChange() {
      if (this.newSale.CLIENTE) {
        for (const singleClient of this.clients) {
          if (singleClient._id === this.newSale.CLIENTE) {
            this.currentClientInformation = singleClient;
            if (singleClient.D_CREDITO && this.newSale.CONDICION_P === "R") {
              this.newSale.PERI_PAGO = singleClient.D_CREDITO;
            }
            if (singleClient.CLAVE_USO_SAT) {
              this.newSale.CLAVE_USO_SAT = singleClient.CLAVE_USO_SAT;
            }
            if (singleClient.CLAVE_REGIMEN) {
              this.newSale.CLAVE_REGIMEN = singleClient.CLAVE_REGIMEN;
            }
            if (singleClient.CLAVE_REGIMEN) {
              this.newSale.CLAVE_REGIMEN = singleClient.CLAVE_REGIMEN;
            }
            if (singleClient.CLAVE_ALMACEN) {
              this.newSale.ALMACEN = singleClient.CLAVE_ALMACEN;
            }
            break;
          }
        }
        this.newSale.currentItems = [];
        this.addRow();
        this.updateTotals();
      }
    },
    handlePaymentConditionChange() {
      this.newSale.PERI_PAGO = 0;
      // Change sale information to the one in client object
      if (this.newSale.CLIENTE) {
        for (const singleClient of this.clients) {
          if (singleClient._id === this.newSale.CLIENTE) {
            if (singleClient.D_CREDITO && this.newSale.CONDICION_P === "R") {
              this.newSale.PERI_PAGO = singleClient.D_CREDITO;
            }
            if (singleClient.CLAVE_USO_SAT) {
              this.newSale.CLAVE_USO_SAT = singleClient.CLAVE_USO_SAT;
            }
            if (singleClient.CLAVE_REGIMEN) {
              this.newSale.CLAVE_REGIMEN = singleClient.CLAVE_REGIMEN;
            }
            break;
          }
        }
      }

      // Change Método de pago to empty
      if (this.newSale.CONDICION_P === "C") {
        this.newSale.FORMA_P = "";
        this.newSale.METODO_P = "P";
      }

      // Change Método de pago according to payment condition
      if (this.newSale.CONDICION_P === "R") {
        this.newSale.FORMA_P = this.porDefinirPaymentId;
        this.newSale.METODO_P = "D";
      }
    },
    handlePaymentMethodChange() {
      if (this.newSale.METODO_P === "D") {
        if (this.newSale.CLIENTE) {
          for (const singleClient of this.clients) {
            if (singleClient._id === this.newSale.CLIENTE) {
              if (singleClient.D_CREDITO && this.newSale.CONDICION_P === "R") {
                this.newSale.PERI_PAGO = singleClient.D_CREDITO;
              }

              break;
            }
          }
        }
        this.newSale.CONDICION_P = "R";
        this.newSale.FORMA_P = this.porDefinirPaymentId;
      } else {
        this.newSale.PERI_PAGO = 0;
        this.FORMA_P =
          this.newSale.CONDICION_P === "R" ? this.porDefinirPaymentId : "";
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     * @param deleteMessage the delete message that the user will see
     */
    startConfirmationEvent(type, functionOnConfirm, name) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalSucces.call(this, type, functionOnConfirm, name);
    },
    openSearchClientUnlock(saleInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SearchClientUnlock,
        props: {
          saleInformation: saleInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    autoCompleteClients(value) {
      if (!this.clients) {
        return [];
      }
      return this.clients.filter((singleClient) => {
        return (
          singleClient.NOMBRE_EMPRESA.toLowerCase().indexOf(
            value.toLowerCase()
          ) >= 0 ||
          singleClient.CLAVE_CLI.toLowerCase().indexOf(value.toLowerCase()) >= 0
        );
      });
    },
    getClientRFC() {
      if (this.newSale.CLIENTE) {
        for (const singleClient of this.clients) {
          if (singleClient._id === this.newSale.CLIENTE) {
            return singleClient.RFC_CLI;
          }
        }
      }

      return "";
    },
    handleRequireInvoice() {},
    checkCanDivideCashInvoice() {
      let canDivideInvoice = false;
      for (const singleItem of this.newSale.currentItems) {
        if (singleItem.service && singleItem.quantity > 1) {
          canDivideInvoice = true;
        }
      }
      return (
        canDivideInvoice &&
        this.newSale.CLIENTE &&
        this.newSale.FORMA_P === this.efectivoPaymentId &&
        this.newSale.total >= 2000 &&
        this.checkIfCanInvoice(this.newSale.CLIENTE) &&
        this.userPermissions &&
        this.userPermissions.accounting.saveInvoiceProfessionalServicesSale ===
          "S"
      );
    },
    /**
     *
     * @desc sends the sale information to the component that opened this component
     */
    selectSale() {
      EventBus.$emit("saleSelected", {
        sale: this.purchaseSale,
      });
      this.closeModal();
    },
    getRandomString(length) {
      return createRandomStringCamps(length);
    },
    handleOptionChange(item) {
      if (item.selectedOption === "deleteItem") {
        this.deleteRow(item);
      }
      setTimeout(() => {
        item.selectedOption = "";
      }, 500);
    },
    checkServiceRepeated(service) {
      // Substract presentations already used
      for (const singleItem of this.newSale.currentItems) {
        if (singleItem.service && singleItem.service._id === service._id) {
          return false;
        }
      }
      return true;
    },
    deleteRow(item) {
      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newSale.currentItems.entries()) {
        if (singleItem.RENGLON === item.RENGLON) {
          this.newSale.currentItems.splice(singleItemIndex, 1);
        }
      }
      this.updateTotals();
    },
    getClientPredialAccounts() {
      if (
        !this.currentClientInformation.CUENTAS_PREDIAL ||
        this.currentClientInformation.CUENTAS_PREDIAL.length === 0
      ) {
        return [];
      }

      return this.currentClientInformation.CUENTAS_PREDIAL;
    },
    handlePredialAccountChange() {
      this.newSale.OBSERVA_IMP = `ARRENDAMIENTO CORRESPONDIENTE AL MES DE ${moment()
        .format("MMMM")
        .toString()
        .toLocaleUpperCase()} 2023`;
    },
  },
  computed: {
    services() {
      return this.$store.getters.SERVICES.map((singleService) => {
        let singleServiceWithProps = { ...singleService };

        if (singleService.FE_ULTVTA) {
          singleServiceWithProps.lastSaleFormat = moment(
            singleService.FE_ULTVTA
          ).format("DD-MM-YYYY HH:mm");
        }

        return singleServiceWithProps;
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES.map((singleWarehouse) => {
        const singleWarehouseWithProps = { ...singleWarehouse };
        return singleWarehouseWithProps;
      }).filter((singleWarehouse) => {
        return singleWarehouse.CLAVE_ALMACEN !== "Transferencias";
      });
    },
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const clientWithProps = { ...singleClient };

        return clientWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_EMPRESA > b.NOMBRE_EMPRESA ? 1 : -1;
      });
    },
    priceLists() {
      return this.$store.getters.PRICES_LISTS.map((singlePriceList) => {
        let singlePriceListWithProps = { ...singlePriceList };
        return singlePriceListWithProps;
      });
    },
    lastSaleNumber() {
      return this.$store.getters.LASTSALENUMBER;
    },
    clientComputed() {
      return this.newSale.CLIENTE;
    },
    checkDisable() {
      if (this.newSale.CONDICION_P === "R") {
        if (
          this.newSale.CLIENTE &&
          this.newSale.ALMACEN &&
          this.newSale.CONDICION_P &&
          this.newSale.CLAVE_REGIMEN &&
          this.newSale.CLAVE_USO_SAT &&
          this.newSale.PERI_PAGO !== "" &&
          this.newSale.METODO_P !== ""
        ) {
          return false;
        }
      } else if (this.newSale.CONDICION_P === "C") {
        if (
          this.newSale.CLIENTE &&
          this.newSale.ALMACEN &&
          this.newSale.CONDICION_P &&
          this.newSale.CLAVE_REGIMEN &&
          this.newSale.CLAVE_USO_SAT &&
          this.newSale.PERI_PAGO !== "" &&
          this.newSale.METODO_P !== "" &&
          this.newSale.FORMA_P !== ""
        ) {
          return false;
        }
      }
      return true;
    },
    checkDisableItems() {
      for (let singleItem of this.newSale.currentItems) {
        if (singleItem.service) return false;
      }
      return true;
    },
    creationDateFomatted() {
      return moment(this.newSale.FECHA).format("ll");
    },
    selectedClientInformation() {
      for (const singleClient of this.clients) {
        if (this.newSale.CLIENTE === singleClient._id) {
          const singleClientWithProps = { ...singleClient };
          singleClientWithProps.remainingCredit =
            singleClient.LIMITE_CRED -
            singleClient.BALANCE_CRED +
            (this.unlockClientInformation &&
            this.unlockClientInformation.CANTIDAD
              ? Number(this.unlockClientInformation.CANTIDAD)
              : 0);

          return singleClientWithProps;
        }
      }
      return undefined;
    },
    checkClientCanPurchase() {
      if (this.newSale.CONDICION_P === "R") {
        if (this.selectedClientInformation) {
          return (
            this.selectedClientInformation.remainingCredit >= this.newSale.total
          );
        }
        return false;
      }
      return true;
    },
    cfdiUses() {
      return this.$store.getters.SATCFDIUSES.map((singleUse) => {
        const useWithProps = { ...singleUse };
        if (useWithProps.TIPO_P === "A") {
          useWithProps.typeFormatted = "Ambos";
        } else if (useWithProps.TIPO_P === "F") {
          useWithProps.typeFormatted = "Persona física";
        } else if (useWithProps.TIPO_P === "M") {
          useWithProps.typeFormatted = "Persona moral";
        }
        return useWithProps;
      });
    },
    regimenes() {
      return this.$store.getters.SATREGIMENES.map((singleRegimen) => {
        const regimenWithProps = { ...singleRegimen };
        if (regimenWithProps.TIPO_R === "A") {
          regimenWithProps.typeFormatted = "Ambos";
        } else if (regimenWithProps.TIPO_R === "F") {
          regimenWithProps.typeFormatted = "Persona física";
        } else if (regimenWithProps.TIPO_R === "M") {
          regimenWithProps.typeFormatted = "Persona moral";
        }
        return regimenWithProps;
      });
    },
    paymentTypes() {
      return this.$store.getters.PAYMENTTYPES;
    },
    bankAccounts() {
      return this.$store.getters.BANKACCOUNTS;
    },
    userPermissions() {
      return this.$store.getters.USER.permissions.permissions;
    },
    user() {
      return this.$store.getters.USER;
    },
    users() {
      return this.$store.getters.USERS;
    },
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        return singleEmployee;
      }).filter((singleEmployee) => {
        return singleEmployee.TIPO === "VE" || singleEmployee.TIPO === "VC";
      });
    },

    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    vehicules() {
      const vehiculesWithProps = [...this.$store.getters.VEHICULES];
      return vehiculesWithProps;
    },
    companyNames() {
      return this.$store.getters.COMPANY_NAMES.filter((singleCompanyName) => {
        return singleCompanyName.STATUS !== "PE";
      });
    },
  },
  watch: {
    employees() {
      if (!this.saleInformation) {
        for (const singleUser of this.users) {
          if (singleUser.email === this.user.email) {
            for (const singleEmployee of this.employees) {
              if (singleUser._id === singleEmployee.USER) {
                this.newSale.VENDEDOR = singleEmployee._id;
              }
            }
          }
        }
      }
    },
    companyInformation() {
      if (this.companyInformation.ENVIAR_MATERIAL_VENTA) {
        this.newSale.sendMaterial = true;
      }
    },
  },
  filters: {
    currency(value) {
      return value.toFixed(5);
    },
    weight(value) {
      value = value.toString();
      return `${value} Kg`;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
@import "../../Configuration/style/Configuration.css";
</style>
