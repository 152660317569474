/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-tooltip
          v-if="warehouses.length === 0 || clients.length === 0"
          label="Primero debes agregar uno o varias listas de precios, clientes y almacenes al sistema o esperar a que el sistema cargue estos datos"
          multilined
        >
          <b-button type="is-primary" disabled>Agregar venta</b-button>
        </b-tooltip>

        <b-button
          type="is-primary"
          @click="openAddSale()"
          v-if="warehouses.length > 0 && clients.length > 0"
          >Agregar venta</b-button
        >
      </div>

      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
      <div class="global-lists-filters-container">
        <b-field>
          <b-field label="Almacenes" expanded label-position="inside">
            <b-taginput
              :data="warehouses"
              autocomplete
              :allow-new="false"
              :open-on-focus="true"
              field="NOMBRE_ALM"
              icon="warehouse"
              v-model="filterWarehouses"
              @add="(value) => changeFilterValue()"
              @remove="(value) => changeFilterValue()"
              ellipsis
              placeholder="Nombre de almacen"
              aria-close-label="Borrar almacen seleccionado"
              check-infinite-scroll
            >
            </b-taginput>
          </b-field>
        </b-field>
      </div>
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de ventas: <b>{{ sales.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="sales"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        scrollable
        ref="sales"
        :checked-rows.sync="checkedSales"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE_VENTA}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        default-sort="CLAVE_VENTA"
      >
        <template #empty>
          <div class="has-text-centered">No hay ventas</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
            :custom-sort="column.customSort"
            :custom-search="column.customSearch"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span
              v-if="column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] | money("MXN", 2) }}</span
            >
            <span
              v-if="!column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] }}</span
            >
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <b-button
                    type="is-primary"
                    @click="showSale(props.row)"
                    v-if="
                      props.row.STATUS_FAC === 'SF' && props.row.STATUS !== 'CA'
                    "
                    >Ver o timbrar venta</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="showSale(props.row)"
                    v-if="
                      props.row.STATUS_FAC === 'TI' ||
                      props.row.STATUS_FAC === 'CA' ||
                      props.row.STATUS_FAC === 'FD' ||
                      props.row.STATUS === 'CA'
                    "
                    >Ver venta</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="showInvoices(props.row)"
                    v-if="
                      props.row.STATUS_FAC === 'TI' ||
                      props.row.STATUS_FAC === 'CA'
                    "
                    >Ver facturas</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="openPrintSalesModal(props.row)"
                    >Enviar o imprimir venta</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="
                      startCancelEvent(
                        'danger',
                        function () {
                          cancelSale(props.row);
                        },
                        'cancelar la venta'
                      )
                    "
                    v-if="
                      props.row.STATUS !== 'CA' && props.row.STATUS_FAC !== 'TI'
                    "
                    >Cancelar venta</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="cancelInvoice(props.row)"
                    v-if="
                      props.row.STATUS !== 'CA' && props.row.STATUS_FAC === 'TI'
                    "
                    >Cancelar venta</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddProfessionalServiceSale from "./AddProfessionalServiceSale";
import ProfessionalServicesInvoiceListModal from "./ProfessionalServicesInvoiceListModal";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";
import { openConfirmationModalDanger } from "../../../utils/component-fns";
import CFDIProfessionalServicesCancellationModal from "../../Accounting/components/CFDIProfessionalServicesCancellationModal";
import PrintProfessionalServicesSales from "../../Global/components/print-formats/PrintProfessionalServicesSales";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "ProfessionalServicesSalesList",
  components: {},
  directives: { cleave },
  data() {
    return {
      perPage: 25,
      checkedSales: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: this.loadColumnsFromLocalStorage(
        [
          {
            field: "CLAVE_VENTA",
            label: "Folio",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "statusFormatted",
            label: "Estatus",
            sortable: true,
            searchable: true,
            display: false,
          },
          {
            field: "statusFacFormatted",
            label: "Estatus de timbrado",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "USUARIO_CREACION",
            label: "Usuario creación",
            sortable: true,
            searchable: true,
            display: false,
          },
          {
            field: "FACTURA_FOR",
            label: "Facturas",
            sortable: true,
            searchable: true,
            display: false,
          },
          {
            field: "NOMBRE_CLIENTE",
            label: "Cliente",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_ALM",
            label: "Almacen",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_VENDEDOR",
            label: "Vendedor",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_ALMACEN_ORIGEN",
            label: "Origen",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "formatedCreationDate",
            label: "Fecha de la venta",
            sortable: true,
            searchable: true,
            display: true,
            customSort: this.sortByDate,
          },
          {
            field: "RESTA_FOR",
            label: "Restante a pagar en cuenta por cobrar",
            sortable: true,
            searchable: true,
            display: false,
            money: true,
            customSort: this.sortyByCXCTotal,
          },
          {
            field: "CUENTA_STATUS_FOR",
            label: "Estatus de cuenta por cobrar",
            sortable: true,
            searchable: true,
            display: false,
          },
          {
            field: "SUBTOTAL",
            label: "Subtotal",
            sortable: true,
            searchable: true,
            display: false,
            money: true,
          },
          {
            field: "IVA",
            label: "Iva total",
            sortable: true,
            searchable: true,
            display: false,
            money: true,
          },
          {
            field: "TOTAL",
            label: "Total",
            sortable: true,
            searchable: true,
            display: true,
            money: true,
            customSort: this.sortyByTotal,
          },
        ],
        "professional-services-sales-list-columns"
      ),
      filterWarehouses: [],
      masks: Masks,
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      refreshLoading: false,
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    this.filterWarehouses = [];
    if (this.userInformation.ALMACENES.length > 0) {
      for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
        for (const singleWarehouse of this.warehouses) {
          if (singleSelectedWarehouse === singleWarehouse._id) {
            this.filterWarehouses.push(singleWarehouse);
          }
        }
      }
    }
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(
        this.$store.dispatch("GETPROFESSIONALSERVICESSALES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouses: this.filterWarehouses.map((singleWarehouse) => {
            return singleWarehouse._id;
          }),
        })
      );
      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
      allPromises.push(this.$store.dispatch("GETPRICESLISTS"));
      allPromises.push(this.$store.dispatch("GETARTICLES"));
      allPromises.push(this.$store.dispatch("GETEMPLOYEES"));
      allPromises.push(this.$store.dispatch("GETDISCOUNTCONCEPTS"));
      allPromises.push(this.$store.dispatch("GETCLIENTS"));
      allPromises.push(this.$store.dispatch("GETCOMMERCIALBUSINESS"));
      allPromises.push(this.$store.dispatch("GETVEHICULES"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    openAddSale() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddProfessionalServiceSale,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    formatSale(sale) {
      let currentItems = [];
      for (let singleArticle of sale.VENTA_DET) {
        currentItems.push({
          _id: singleArticle._id,
          service: singleArticle.CLAVE_SERVICIO,
          CONCEP_IE: singleArticle.CONCEP_IE,
          CLAVE_SERVICIO: singleArticle.CLAVE_SERVICIO,
          CANTIDAD: singleArticle.CANTIDAD,
          RENGLON: singleArticle.RENGLON,
          PRECIO_U: singleArticle.PRECIO_U,
          PORC_IVA: singleArticle.PORC_IVA ? singleArticle.PORC_IVA : 16,
          PORC_IEPS: singleArticle.PORC_IEPS ? singleArticle.PORC_IEPS : 0,
          IVA_GTO: singleArticle.IVA_GTO,
          IEPS_GTO: singleArticle.IEPS_GTO,
          TOTAL: singleArticle.TOTAL,
          COST_GTO: singleArticle.COST_GTO,
          DESC_GTO: singleArticle.DESC_GTO,
          selectedOption: "empty",
          EXENTO: singleArticle.EXENTO ? singleArticle.EXENTO : "N",
          PORC_RETENCION_ISR: singleArticle.PORC_RETENCION_ISR,
          PORC_RETENCION_IVA: singleArticle.PORC_RETENCION_IVA,
          RETENCION_ISR_GTO: singleArticle.RETENCION_ISR_GTO,
          RETENCION_IVA_GTO: singleArticle.RETENCION_IVA_GTO,
          COSTO_UNI: singleArticle.PRECIO_U,
          ES_ACTIVOF: singleArticle.ES_ACTIVOF,
          DESCRIBEAR: singleArticle.DESCRIBEAR,
          SUB_TOT: singleArticle.SUB_TOT,
          ES_EXENTO: singleArticle.ES_EXENTO,
        });
      }
      let saleInformation = {
        _id: sale._id,
        CLAVE_VENTA: sale.CLAVE_VENTA,
        CLIENTE: sale.CLAVE_CLIENTE._id,
        ALMACEN: sale.CLAVE_ALMACEN._id,
        COSTOS: [],
        DESCS: [],
        TIPO: sale.TIPO,
        OBSERVA: sale.OBSERVA,
        OBSERVA_IMP: sale.OBSERVA_IMP,
        currentItems: currentItems,
        FECHA: sale.FECHA,
        subtotal: sale.SUBTOTAL,
        total: sale.TOTAL,
        discounts: sale.DESC_GTO,
        costs: sale.COST_GTO,
        iva: sale.IVA,
        ieps: sale.IEPS,
        STATUS: sale.STATUS,
        CLAVE_USO_SAT: sale.CLAVE_USO_SAT,
        CLAVE_REGIMEN: sale.CLAVE_REGIMEN,
        METODO_P: sale.METODO_P,
        CONDICION_P: sale.CONDICION_P,
        PERI_PAGO: sale.PERI_PAGO,
        FORMA_P: sale.FORMA_P,
        REFERENCIA: sale.REFERENCIA,
        NO_CUENTA: sale.NO_CUENTA,
        NO_CLABE: sale.NO_CLABE,
        NO_TARJETA: sale.NO_TARJETA,
        NO_CHEQUE: sale.NO_CHEQUE,
        STATUS_FAC: sale.STATUS_FAC,
        requireInvoice: sale.requireInvoice,
        requireInvoiceAfterSend: sale.requireInvoiceAfterSend,
        requireParcialInvoiceAfterSend: sale.requireParcialInvoiceAfterSend,
        VENDEDOR: sale.VENDEDOR,
        USUARIO_CREACION: sale.USUARIO_CREACION,
        USUARIO_CONFIRMACION: sale.USUARIO_CONFIRMACION,
        ALMACEN_ORIGEN_INFO: sale.ALMACEN_ORIGEN_INFO,
        RETENCION_ISR_GTO: sale.RETENCION_ISR_GTO,
        RETENCION_IVA_GTO: sale.RETENCION_IVA_GTO,
        RAZON_SOCIAL: sale.RAZON_SOCIAL,
      };

      return saleInformation;
    },
    showSale(sale) {
      let newSale = this.formatSale(sale);
      let currentIndex = newSale.currentItems.length;
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddProfessionalServiceSale,
        props: {
          currentIndexProp: currentIndex,
          saleInformation: newSale,
          blockEdit: true,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    async changeFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETPROFESSIONALSERVICESSALES", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
            warehouses: this.filterWarehouses.map((singleWarehouse) => {
              return singleWarehouse._id;
            }),
          })
        );

        await Promise.all(allPromises);
      }
    },
    sortyByTotal(a, b, isAsc) {
      if (isAsc) {
        return a.TOTAL.localeCompare(b.TOTAL, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      } else {
        return b.TOTAL.localeCompare(a.TOTAL, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      }
    },
    sortyByCXCTotal(a, b, isAsc) {
      if (isAsc) {
        return a.RESTA_FOR.localeCompare(b.RESTA_FOR, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      } else {
        return b.RESTA_FOR.localeCompare(a.RESTA_FOR, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      }
    },
    showInvoices(saleInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ProfessionalServicesInvoiceListModal,
        props: {
          saleInformation: saleInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    checkCanDelete(saleInformation) {
      const isToday = moment(saleInformation.FECHA).isSame(moment(), "day");
      return isToday;
    },
    checkCanDeleteInvoice(saleInformation) {
      return (
        moment(saleInformation.FECHA).isSame(new Date(), "year") &&
        moment(saleInformation.FECHA).isSame(new Date(), "month")
      );
    },
    cancelInvoice(sale) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: CFDIProfessionalServicesCancellationModal,
        props: {
          saleId: sale._id,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async cancelSale(sale) {
      try {
        let response = await this.$store.dispatch(
          "CANCELPROFESSIONALSERVICESSALE",
          {
            saleId: sale._id,
          }
        );
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se canceló exitosamente la venta",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al cancelar la venta en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al cancelar la venta en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openPrintSalesModal(sale) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PrintProfessionalServicesSales,
        props: {
          saleInformation: sale,
          title: "Venta",
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    warehouses() {
      this.filterWarehouses = [];
      if (this.userInformation.ALMACENES.length > 0) {
        for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
          for (const singleWarehouse of this.warehouses) {
            if (singleSelectedWarehouse === singleWarehouse._id) {
              this.filterWarehouses.push(singleWarehouse);
            }
          }
        }
      }
    },
    columns: {
      handler(newColumns) {
        this.saveColumnsToLocalStorage(
          newColumns,
          "professional-services-sales-list-columns"
        );
      },
      deep: true,
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    sales() {
      return this.$store.getters.PROFESSIONALSERVICESSALES.map((singleSale) => {
        const saleWithProps = { ...singleSale };

        saleWithProps.TOTAL = String(singleSale.TOTAL);

        if (singleSale.CLAVE_ALMACEN) {
          saleWithProps.NOMBRE_ALM = singleSale.CLAVE_ALMACEN.NOMBRE_ALM;
        }

        if (singleSale.CLAVE_CLIENTE) {
          saleWithProps.NOMBRE_CLIENTE =
            singleSale.CLAVE_CLIENTE.NOMBRE_EMPRESA;
        }

        saleWithProps.formatedCreationDate = moment(singleSale.FECHA).format(
          "DD-MM-YYYY HH:mm"
        );

        saleWithProps.FACTURA_FOR = "";
        if (singleSale.FACTURAS) {
          saleWithProps.FACTURA_FOR = singleSale.FACTURAS.map(
            (singleInvoice) => {
              return singleInvoice.CLAVE_FACTURA;
            }
          ).toString();
        }

        if (saleWithProps.STATUS === "AC") {
          saleWithProps.statusFormatted = "Activa";
        } else if (saleWithProps.STATUS === "CA") {
          saleWithProps.statusFormatted = "Cancelada";
          saleWithProps.RESTA = 0;
        } else if (saleWithProps.STATUS === "AN") {
          saleWithProps.statusFormatted = "Activa Nota Crédito";
        }

        if (!saleWithProps.STATUS_FAC || saleWithProps.STATUS_FAC === "SF") {
          saleWithProps.statusFacFormatted = "Sin timbrar";
        } else if (saleWithProps.STATUS_FAC === "TI") {
          saleWithProps.statusFacFormatted = "Timbrada";
        } else if (saleWithProps.STATUS_FAC === "CA") {
          saleWithProps.statusFacFormatted = "Cancelada";
        } else if (saleWithProps.STATUS_FAC === "FD") {
          saleWithProps.statusFacFormatted = "En factura global";
        } else if (saleWithProps.STATUS_FAC === "PF") {
          saleWithProps.statusFacFormatted = "Parcialmente timbrada";
        } else if (saleWithProps.STATUS_FAC === "PF") {
          saleWithProps.statusFacFormatted = "Pendiente de timbrar";
        }

        saleWithProps.RESTA_FOR = 0;
        if (saleWithProps.CUENTAS_COB) {
          for (const singleIncome of saleWithProps.CUENTAS_COB) {
            saleWithProps.RESTA_FOR += singleIncome.RESTA;
          }
          saleWithProps.RESTA_FOR =
            saleWithProps.RESTA_FOR > 0 ? String(saleWithProps.RESTA_FOR) : 0;
        }

        saleWithProps.CUENTA_STATUS_FOR = "Pendiente de pagar";
        if (saleWithProps.CUENTAS_COB) {
          for (const singleIncome of saleWithProps.CUENTAS_COB) {
            if (singleIncome.STATUS === "AC")
              saleWithProps.CUENTA_STATUS_FOR = "Pendiente de pagar";
            if (singleIncome.STATUS === "PE")
              saleWithProps.CUENTA_STATUS_FOR = "Pendiente de pagar";
            if (singleIncome.STATUS === "PA")
              saleWithProps.CUENTA_STATUS_FOR = "Totalmente pagada";
            if (singleIncome.STATUS === "CA") {
              saleWithProps.CUENTA_STATUS_FOR = "Cancelada";
              saleWithProps.RESTA_FOR = 0;
            }
            if (singleIncome.STATUS === "VE") {
              saleWithProps.CUENTA_STATUS_FOR = "Vencida";
            }
          }
        }

        return saleWithProps;
      });
    },
    articles() {
      return this.$store.getters.ARTICLES.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        return articleWithProps;
      });
    },
    clients() {
      return this.$store.getters.CLIENTS;
    },
    priceLists() {
      return this.$store.getters.PRICES_LISTS.map((singlePriceList) => {
        let singlePriceListWithProps = { ...singlePriceList };
        return singlePriceListWithProps;
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    userInformation() {
      return this.$store.getters.USER;
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
