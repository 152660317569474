<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Servicios profesionales </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Servicios" icon="doctor">
        <ProfessionalServicesList />
      </b-tab-item>
      <b-tab-item label="Ventas de servicios profesionales" icon="account-tie">
        <ProfessionalServicesSalesList />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import ProfessionalServicesSalesList from "../components/ProfessionalServicesSalesList";
import ProfessionalServicesList from "../components/ProfessionalServicesList";

export default {
  name: "AllProfessionalServices",
  components: {
    ProfessionalServicesSalesList,
    ProfessionalServicesList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "professionalServices");
  },
  async created() {
    let allPromises = [];
    allPromises.push(
      this.$store.dispatch("GETPROFESSIONALSERVICESSALES", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        endDate: new Date(),
        warehouses: [],
      })
    );

    allPromises.push(this.$store.dispatch("GETDISCOUNTCONCEPTS"));
    allPromises.push(this.$store.dispatch("GETCOMMERCIALBUSINESS"));
    allPromises.push(this.$store.dispatch("GETSATCFDIUSES"));
    allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
    allPromises.push(this.$store.dispatch("GETSATREGIMENES"));
    allPromises.push(this.$store.dispatch("GETPACKAGINGS"));
    allPromises.push(this.$store.dispatch("GETTRASNPORTATIONPERMISSIONS"));
    allPromises.push(this.$store.dispatch("GETVEHICULES"));
    allPromises.push(this.$store.dispatch("GETTRANSPORTATIONCONFIGURATION"));
    allPromises.push(this.$store.dispatch("GETTRAILERTYPE"));
    allPromises.push(this.$store.dispatch("GETWEIGHTUNITS"));
    allPromises.push(this.$store.dispatch("GETMEASURES"));
    allPromises.push(this.$store.dispatch("GETDANGEROUSMATERIALS"));
    allPromises.push(this.$store.dispatch("GETLASTCLARIFICATIONNUMBER"));
    allPromises.push(this.$store.dispatch("GETLASTCREDITNOTENUMBER"));
    allPromises.push(this.$store.dispatch("GETCOMPANYNAMES"));

    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
